export const allowlistAddresses = [
    ["0xd674fe4ba3b9bcd2ec84e6706c7d8fc9277d5ce3",11524],
    ["0xdfc2f72e899d841ef451ec1765fd7623d59d75fc",13620],
    ["0x38510319f3ff161fb671ba1f37870be91cae982d",12998],
    ["0x9aaf0142026ccda1326d1b77d4b15777776443a1",13719],
    ["0x4db3bc648e5e550d97b96522ddbd58e48c5fa665",13780],
    ["0x42b9fa98953b51e2c4a96722fb67460ea468d814",13747],
    ["0x0d1de6507595f78bfb86fa1b3288e6fb848b7989",13309],
    ["0x635cab3d1df1c87e3c6534b85f24cc794d883698",13722],
    ["0x9f3e593474fda86dd892e57b8646f23f0f80ad09",13551],
    ["0x0e1c26502dd01189bf8a3f6eb8438e10bdc76a02",13772],
    ["0x56f7b803f3880d3f2146d8d547eb1c35032b8986",13733],
    ["0xa3d98d21a7d4efe8d12b2c15353bfe715bc78847",13721],
    ["0x92965b744ad8a77ed729e887fcefa15e24083657",13260],
    ["0x8e6e8f9f6c388332c321a8f4ae4e6242f084eb22",13769],
    ["0xd0bd462e4655d47445635eda0ce137c2acd976f6",13753],
    ["0xfb468a9b01dcd0ab205e6df34abd9e994b024d97",13775],
    ["0xe2c998e74c152e8af4b99bd844f97b7612549101",13705],
    ["0x27c3dcc552a00b08bed774e0f518443d4528880d",13661],
    ["0xdda0cb6ea8d08bcd75fb17d2b1549f8a9f2a2bc0",13748],
]